<template>
  <div>
    <div class="mb-10">
      <router-link :to="{ name: 'auth.login' }">
        <small class="muted--text">Back to Login</small>
      </router-link>
      <h1 title="Login" class="mb-0">Forgot Password</h1>
      <p class="muted--text">
        Please enter your registered email address to continue
      </p>

      <v-alert
        dismissible
        v-if="undefined != err.message"
        type="error"
        class="mb-8"
        outlined
        dense
      >
        {{ err.message }}
      </v-alert>
      <v-alert
        dismissible
        v-if="isShowSuccess"
        type="success"
        class="mb-8"
        outlined
        dense
      >
        Reset password link sent on your email id.
      </v-alert>
    </div>
    <v-form
      @submit.prevent="validate"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div class="mb-3">
        <v-text-field
          type="email"
          outlined
          label="Email"
          prepend-inner-icon="mdi-at"
          class="dt-text-field"
          v-model="email"
          :rules="emailRules"
        ></v-text-field>
      </div>

      <div class="text-center mb-3">
        <v-btn
          block
          x-large
          color="primary"
          exact
          :loading="loadingSave"
          :disabled="loadingSave"
          type="submit"
        >
          <span v-text="'Continue'"></span>
        </v-btn>
      </div>

      <div class="mb-3">
        <small>
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy" target="_blank"
            >Privacy Policy</a
          >
          and
          <a href="https://policies.google.com/terms" target="_blank"
            >Terms of Service</a
          >
          apply.
        </small>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      email: null,
      valid: true,
      loadingSave: false,
      isShowSuccess: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      recaptchaToken: null,
    };
  },
  computed: {
    ...mapGetters({
      err: "auth/GET_ERR",
    }),
  },
  methods: {
    ...mapActions({
      actionForgotPassword: "auth/actionForgotPassword",
    }),

    async validate() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.loadingSave = true;
      await this.forgotPassword();
    },

    async forgotPassword() {
      let self = this;
      await grecaptcha.ready(async function () {
        let token = await grecaptcha.execute(
          process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          { action: "submit" }
        );
        await self.actionForgotPassword({
          email: self.email,
          token,
        });
        if (self.err.message == undefined) {
          self.isShowSuccess = true;
          self.$refs.form.reset();
        }
        self.loadingSave = false;
      });
    },
  },
};
</script>

<style></style>
